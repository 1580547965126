.formContainer {
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto;
  width: 500px;
  height: 807px;
  padding-top: 200px;
}

@media (max-width: 600px) {
  .formContainer {
    width: 100%;
    height: 50%;
    padding-top: 0px;
    height: 50%;
  }
}
