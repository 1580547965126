.Enrolamiento {
    background-color: #EEF0F8 !important;
}

.SuccessContainer{
    height: 800px;
}

.FormContainer{
    width: 38%;
}
@media (max-width: 600px) {
    .FormContainer{
        width: 60%;
    }
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
}