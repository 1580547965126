.CardPatologia {}

.iconLink {
    background-color: transparent;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.lined {
    font-size: 1rem;
    background-color: transparent !important;
    border: 2px solid #fff !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.65rem 1.5rem;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    border-radius: 0.42rem;
}

.link:hover {
    background-color: #fff !important;
    color: #F36633 !important;
}
