.register-success {
  &__msg {
    color: green;
    margin-bottom: 24px;
  }
  &__img {
    width: 100%;
  }
  &__btn {
    display: inline-block;
    color: white;
    background: #F36633;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px;
    font-weight: bold;
    &:hover {
      color: white;
      opacity: 0.5;
    }
  }
}

@media screen and (min-width: 768px) {
  .register-success{
    height: 91vh;
  }
}