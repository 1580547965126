.PoliticasDeCookies {
    background-color: white;
}
.content{
    padding-top: 70px;
    text-align: left;
}

h2{
    font-size: 32px;
}
.ancla {
  color: #389eb6;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: bold;
  display: block;
}

.ancla:hover {
  color: #389eb6;
}

.title {
  color: #F36633;
  font-weight: bold;
  padding-top: 70px;
  padding-bottom: 20px;
}
.list {
  font-size: 16px;
}