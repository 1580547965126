.formContainer{
    height: 870px;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: auto;
    padding-top: 150px;
    width: 500px;
}

@media (max-width: 600px) {
    .formContainer {
      width: 100%;
      height: 50%;
      padding-top: 50px;
      height: 50%;
    }
}
